
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';
import {Link } from "react-router-dom"; 

import { useHistory } from "react-router-dom";



function Cancel(props) {

  
  useEffect( () => {
     window.scrollTo(0, 0);
 }, []);




    return (
      <>
       <div className="pagesss">
        <div className="container">
          <div className="row">
            <div className="col-md-12" style={{padding:0}}>
              <div className="breadcrumbs">
                 <div className="row aboutssss2"><div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <h1> Shipping Policy </h1>
                 
                 <div className="default__desc book__desc__preview termsss">
                    <h3>General</h3>
                        <p> a. This Shipping Policy governs the shipment and delivery of any items purchased through   <a href="https://giobooks.com/">giobooks</a> ("Website").</p>
                        <p> b. Please note that our primary offerings are digital products and services which do not require physical delivery.</p>

                    <h3>Physical Products</h3>
                        <p> a. If any physical products or merchandise are available for purchase, the following terms apply.</p>
                        <p> b. Ensure to check the product description for any specific shipping details or exceptions.</p>

                    <h3>Shipping Locations</h3>
                        <p> a. We ship to locations within India and internationally.</p>
                        <p> b. Delivery charges and timeframes vary based on the delivery location and product weight.</p>

                    <h3>Shipping Charges</h3>
                        <p> a. Our shipping charges are determined by the weight of your order and the location to which the product is shipped. </p>
                        <p> b. Shipping costs will be calculated and displayed at the checkout. </p>

                    <h3>Delivery Timeframes</h3>
                        <p>a. Domestic Orders: Typically take 10 business days to be delivered.</p>
                        <p>b. International Orders: Typically take 30 business days to be delivered.</p>
                        <p>c. Note: “Business Days” exclude weekends and public holidays.</p>
                        

                        <h3>Order Tracking</h3>

                        <p>a. Once your order has been shipped, you will receive an email notification with the shipping details and a tracking number.</p>

                        <p>b. You can track your order by visiting our website and logging into your account, or directly through the courier’s website using the tracking number.</p>

                        <h3>Delivery Issues</h3>

                        <p>a. If you experience any problems with your delivery (e.g., non-receipt), please contact our Customer Service team at info@giobooks.com</p>

                        <h3>Customs, Duties, and Taxes</h3>

                        <p>a. International orders may be subject to customs and duty charges, which are levied once a shipment reaches your country.</p>

                        <p>b. It is your responsibility to pay these charges to release your order from customs.</p>


                        <h3>Returns</h3>

                        <p>a. Please refer to our Return and Refund Policy(https://giobooks.com/page/CancellationAndRefundPolicy) for information about returning products.</p>

                        <h3>Changes to Shipping Policy</h3>

                        <p>a. We may update this Shipping Policy as required. We encourage you to review this page periodically.</p>

                        <p>b. Your continued use of our services after any changes signifies your acceptance of our updated Shipping Policy.</p>

                        <p>Contact Us If you have any questions related to shipping, please contact us at:</p>

                        <p>Email: info@giobooks.com</p>

                        <p>Phone: +91 7397628478</p>

                       
                    
                    </div>


                
              </div>
            </div>


                
              </div>
            </div>
      
         
      

       </div>
  </div>
</div>
      </>
    );
  }


export default Cancel;