import React,{useState,useEffect,useContext} from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import { ToastContainer} from "react-toastify";
import {NavLink, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MyContext } from '../contexts/MyContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import '../App.css';

import logo from '../Img/logo.png';

import Navbarmenu from './Navbarmenu';
import Home from './Home';
import Loginsection from './Loginsection';
import Resetpassword from './Resetpassword';
import Myaccount from './Myaccount';
import Logout from './Logout';
import Contmail from './Contmail';

import ShippingPolicy from './ShippingPolicy';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import CancellationAndRefundPolicy from './CancellationAndRefundPolicy';

import Bookadd from './Bookadd';
import Bookaddtwo from './Bookaddtwo';
import Bookaddtwopreview from './Bookaddtwopreview';
import Bookpreview from './Bookpreview';
import Bookupgradepay from './Bookupgradepay';
import PaypalBookSuccess from './PaypalBookSuccess';
import StripeBookSuccess from './StripeBookSuccess';
import PaypalBookupgradeSuccess from './PaypalBookupgradeSuccess';
import StripeBookupgradeSuccess from './StripeBookupgradeSuccess';
import Cancel from './Cancel';


import AddAudio from './AddAudio';
import AddAudiopreview from './AddAudiopreview';
import PaypalAudioSuccess from './PaypalAudioSuccess';
import StripeAudioSuccess from './StripeAudioSuccess';
import AudioBookPreview from './AudioBookPreview';

import AddPublisher from './AddPublisher';
import AddPublisherpreview from './AddPublisherpreview';
import PaypalPublisherSuccess from './PaypalPublisherSuccess';
import StripePublisherSuccess from './StripePublisherSuccess';
import PublisherPreview from './PublisherPreview';

import AddBookTrailer from './AddBookTrailer';
import AddBookTrailerpreview from './AddBookTrailerpreview';
import PaypalvideoSuccess from './PaypalvideoSuccess';
import StripevidoSuccess from './StripevidoSuccess';
import BookTrailers from './BookTrailers';

import Pendingbook from './Pendingbook';
import Pendingaudio from './Pendingaudio';
import Pendingvideo from './Pendingvideo';
import Pendingpublisher from './Pendingpublisher';


import PriceUpdate from './PriceUpdate';
import BestDeals from './BestDeals';
import ImpressiveBooks from './ImpressiveBooks';
import FeaturedBooks from './FeaturedBooks';
import StandardBooks from './StandardBooks';
import FreeBooks from './FreeBooks';
import AudioBooks from './AudioBooks';
import Publishers from './Publishers';
import BookTrailersPreview from './BookTrailersPreview';

import Adminbookapproval from './Adminbookapproval';
import Adminvideoapproval from './Adminvideoapproval';
import AdminAudioapproval from './AdminAudioapproval';
import Adminpublisherapproval from './Adminpublisherapproval';
import AdminFreeapproval from './AdminFreeapproval';




import Search from './Search';
import Demo from './Demo';





import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas} from '@fortawesome/free-solid-svg-icons';
library.add(fas, fab)

let BaseURLuploads = 'https://giobooks.com/uploads/';


function Dashboard() {
  const {profilephotos} = useContext(MyContext);
  const [isprofilephotos, setisprofilephotos] = useState([]);

  useEffect( () => {
    window.addEventListener('scroll', toggleVisibility);
    profilephotosimpressive();
  }, []);
  
  const profilephotosimpressive = async() =>{
    try {
           const profilephotosqqq = await profilephotos();
                  if(profilephotosqqq.success === 1){
                         console.log(profilephotosqqq);
                         setisprofilephotos(profilephotosqqq.profilephotosall) ;
           }
    } catch (error) { throw error;}
}
  const scrollToTop =()=> {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  
const [is_visible, set_visible] = useState(false);

const toggleVisibility =()=> {
  if (window.pageYOffset > 700) {
    set_visible(true);
  
  } else {
    set_visible(false);
  }
}


  return (
    <BrowserRouter basename="/">
      <ToastContainer />
        <div className="App">
           <div className="container">
              <div className="row align-items-center">
                <Navbarmenu/>
                
              </div>
            </div>
            </div>
        
          <Switch>
    

         <Route exact path="/" component={Home}/>
         <Route exact path="/Login" component={Loginsection}/>
         <Route exact path="/ResetPassword/:passkey" component={Resetpassword}/>
         <Route exact path="/Myaccount" component={Myaccount}/>
         <Route exact path="/Logout" component={Logout}/>
         <Route exact path="/Contact" component={Contmail}/>


         <Route exact path="/ShippingPolicy" component={ShippingPolicy}/>
         <Route exact path="/PrivacyPolicy" component={PrivacyPolicy}/>
         <Route exact path="/TermsOfService" component={TermsOfService}/>
         <Route exact path="/CancellationAndRefundPolicy" component={CancellationAndRefundPolicy}/>






         <Route exact path="/PostYourBook" component={Bookadd}/>
         <Route exact path="/3b2ff1b2e60e958349f58d860c1babd6/:Add" component={Bookaddtwo}/>
         <Route exact path="/5ba3c8d60e477b46681416aa408156ad/:Addpreview" component={Bookaddtwopreview}/>
         <Route exact path="/Bookpreview/:Addpreview" component={Bookpreview}/>
         <Route exact path="/Bookupgradepay/:Addpreview" component={Bookupgradepay}/>
         <Route exact path="/StripeBookSuccess/:Addpreview" component={StripeBookSuccess}/>
         <Route exact path="/PaypalBookSuccess/:Addpreview" component={PaypalBookSuccess}/>
<Route exact path="/PaypalBookupgradeSuccess/:Addpreview" component={PaypalBookupgradeSuccess}/>
         <Route exact path="/StripeBookupgradeSuccess/:Addpreview" component={StripeBookupgradeSuccess}/>
         
         <Route exact path="/Cancel" component={Cancel}/>

         <Route exact path="/AddAudio" component={AddAudio}/>
         <Route exact path="/dc500506ebb067a6e40f7ccceaff4af0/:Addpreview" component={AddAudiopreview}/>
         <Route exact path="/StripeAudioSuccess/:Addpreview" component={StripeAudioSuccess}/>
         <Route exact path="/PaypalAudioSuccess/:Addpreview" component={PaypalAudioSuccess}/>
         <Route exact path="/AudioBookPreview/:Addpreview" component={AudioBookPreview}/>

         <Route exact path="/AddPublisher" component={AddPublisher}/>
         <Route exact path="/02b48f3ed729d18dcdb4cfe75c2ece5a/:Addpreview" component={AddPublisherpreview}/>
         <Route exact path="/StripePublisherSuccess/:Addpreview" component={StripePublisherSuccess}/>
         <Route exact path="/PaypalPublisherSuccess/:Addpreview" component={PaypalPublisherSuccess}/>
         <Route exact path="/PublisherPreview/:Addpreview" component={PublisherPreview}/>

         <Route exact path="/BookTrailers" component={BookTrailers}/>
         <Route exact path="/AddBookTrailer" component={AddBookTrailer}/>
         <Route exact path="/f93718ba896b0ec0ec82e0decfa8ece7/:Addpreview" component={AddBookTrailerpreview}/>
         <Route exact path="/StripevidoSuccess/:Addpreview" component={StripevidoSuccess}/>
         <Route exact path="/PaypalvideoSuccess/:Addpreview" component={PaypalvideoSuccess}/>

         <Route exact path="/BookPaymentPending/:bookviews" component={Pendingbook}/>
         <Route exact path="/AudioBookPaymentPending/:bookviews" component={Pendingaudio}/>
         <Route exact path="/BookTrailerPaymentPending/:bookviews" component={Pendingvideo}/>
         <Route exact path="/PublisherPaymentPending/:bookviews" component={Pendingpublisher}/>



         <Route exact path="/PriceUpdate" component={PriceUpdate}/>
         <Route exact path="/BestDeals" component={BestDeals}/>
         <Route exact path="/ImpressiveBooks" component={ImpressiveBooks}/>
         <Route exact path="/FeaturedBooks" component={FeaturedBooks}/>
         <Route exact path="/StandardBooks" component={StandardBooks}/>
         <Route exact path="/FreeBooks" component={FreeBooks}/>
         <Route exact path="/AudioBooks" component={AudioBooks}/>
         <Route exact path="/Publishers" component={Publishers}/>
         <Route exact path="/BookTrailersPreview/:Addpreview" component={BookTrailersPreview}/>

         <Route exact path="/Adminbookapproval" component={Adminbookapproval}/>
         <Route exact path="/Adminvideoapproval" component={Adminvideoapproval}/>
         <Route exact path="/AdminAudioapproval" component={AdminAudioapproval}/>
         <Route exact path="/Adminpublisherapproval" component={Adminpublisherapproval}/>
         <Route exact path="/AdminFreeapproval" component={AdminFreeapproval}/>


         <Route exact path="/Search" component={Search}/>
         <Route exact path="/Demo" component={Demo}/>
        
          </Switch>
        
  

      

          <div className="Appfooter">
            <div className="container">
              <div className="row align-items-center">
              <ul className="ttt7">
                            <li className="menu-item"><NavLink exact  to="/">Home</NavLink></li>
                           
                            <li className="menu-item"><NavLink to="/ImpressiveBooks/" > Impressive Books </NavLink></li>
                            <li className="menu-item"><NavLink  to="/BestDeals/" > Best Deals </NavLink></li>
                            <li className="menu-item"><NavLink  to="/FeaturedBooks/" > Featured Books </NavLink></li>
                            <li className="menu-item"><NavLink  to="/StandardBooks/" > Standard Books </NavLink></li>
                                  
                          
                            <li className="menu-item"><NavLink  to="/BookTrailers" > Book Trailers </NavLink></li>
                                  
                            
                            <li className="menu-item"><NavLink to="/AudioBooks" > Audio Books </NavLink></li>
                                  
                              
                            <li className="menu-item"><NavLink to="/Publishers" > Publishers </NavLink></li>
                                  
                           <li className="menu-item"><NavLink  to="/FreeBooks" > free book listing </NavLink></li>
                                  
                                
                               
                        </ul>
              </div>
            </div>
          </div>
          <footer className="copyright-block copyright-layout-simple">
		      	<div className="container">
            <div className="col-md-4" style={{padding:0}}>
	            <span className="logo-footer"> <img src={logo} alt="logo" /> <p>gioBooks is an international site that allows authors to list and promote their books. It was developed with books search optimized technology. Books posted here are promoted to our email subscribers and huge social media followers on Facebook and LinkedIn. We promote, ebooks, audiobooks, book trailers, and publisher details. www.gioBooks(dot)com</p> 
              </span>
              </div>
              <div className="col-md-6" style={{padding:0}}>
              <div className="ttt8" > 
         {isprofilephotos.map((item, index) => (
                <div key={item.books_id} className="col-md-2 col-sm-4 ttt8s" > 
          <Link  to={`/Bookpreview/${item.books_id}`}  >
                 <span>{item.name}</span>
                 
                     <img src={BaseURLuploads + item.profile_image} alt="books" />
                    
                     </Link>
         </div>
          )) }
   </div>

   <p><Link to="/" > Gio Books </Link> © All Rights Reserved - 2021 </p>







              </div>







              <span className="menu-itemcopyright sqw__listsss">
                <NavLink  to="/Contact" >   Contact Form </NavLink>
                <NavLink  to="/PrivacyPolicy" > Privacy Policy </NavLink>
                <NavLink  to="/TermsOfService" >   Terms Of Service </NavLink>
                <NavLink  to="/CancellationAndRefundPolicy" >   Cancellation And RefundPolicy </NavLink>
                <NavLink  to="/ShippingPolicy" >  Shipping Policy </NavLink>
               
                </span>

              <div className="clearfix"></div>
    
              
              		</div>
		</footer>



    {is_visible && ( <>
              <span onClick={scrollToTop} className="scrolls"><FontAwesomeIcon icon={['fas','arrow-up']} className="gold" /></span><span className="ltx-hex"><span className="ltx-hex-inner"></span></span>	
              </>
              )}
       
    </BrowserRouter>
  );
}

export default Dashboard;
