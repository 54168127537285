import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import ReCAPTCHA from "react-google-recaptcha";
import Loader from 'react-loader-spinner'
import Axios from 'axios';

import {toast} from "react-toastify";
import { propTypes } from 'react-bootstrap/esm/Image';

const TEST_SITE_KEY = "6LcQpbYZAAAAAA0gDuyJbNttVUJX3U9TWzqEo4zG";
const DELAY = 1500;


function Registerpage(props) {

  const {RegisterUser} = useContext(MyContext);
    
  const [value, setvalue] = useState("[empty]");
  const [load, setload] = useState(false);
  const [recaptchaLoaded, setrecaptchaLoaded] = useState(false);
  const [isLoading, setisLoading] = useState(false);


  const [checked, setChecked] = useState(false);

  const [loadinglogin, setloadinglogin] = useState(false);
  const _reCaptchaRef = React.createRef()
  const [userInfo, setuserInfo] = useState({
    name: '',
    email: '',
    password: '',
    conformpassword: '',
});
const onChangeValue = (e) => {
    setuserInfo({
        ...userInfo,
            [e.target.name]:e.target.value
    });
} 

  useEffect( () => {
    setTimeout(() => {
      setload(true );
    }, DELAY);
    console.log("didMount - reCaptcha Ref-", _reCaptchaRef);
   }, []); 

 
  const handleChange = (value) => {
    setvalue(value);
   
  };
  const asyncScriptOnLoad = () => {
    setrecaptchaLoaded(true);
    _reCaptchaRef.current.execute();
  };
  
 const Register = async (event) => {

  setloadinglogin(true);
  
  event.preventDefault();
  event.persist();
  if(userInfo.password !== userInfo.conformpassword){
    toast.error('password and confirm password does not match.',{
      position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
    });  
    setloadinglogin(false);
    return;
  }
  const data = await RegisterUser(userInfo,userInfo.recaptcha_response.value);
  if(data.success === 1){
    setloadinglogin(false);
    toast('Create an account successfully');
    event.target.reset(); 
    props.LoginMode();
  }
  else{
    setloadinglogin(false);
    toast.error( data.msg, {
        position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
      });  
}

}
    

     
        //const { value, load, recaptchaLoaded,isLoading } = state || {};
        return(
            <form onSubmit={Register} className="login-forms">
              <div className="breadcrumbs">
                            <div className="content-box">
                                <h1>  Create a new account to post your book </h1>
                            </div>
                      </div>
                <div className="clearfix"></div>
            <div className="form-row">
            
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Full Name</label>
                    <input type="text" name="name" value={userInfo.name} onChange={onChangeValue}className="form-control" placeholder="Your name here..." required   />
                   
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Email Address</label>
                    <input type="email" name="email" value={userInfo.email} onChange={onChangeValue}className="form-control" placeholder="Your email here..." required />
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold"> Password </label>
                    <input type="password" name="password" value={userInfo.password} onChange={onChangeValue}className="form-control" placeholder="Your password here..."  required />
                    
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold"> Confirm Password </label>
                    <input type="password" name="conformpassword" value={userInfo.conformpassword} onChange={onChangeValue} className="form-control" placeholder="Your password here..." required />
                </div>
                <div className="login__checkbox form-group col-md-12">
                <label className=" font-weight-bold ">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    className="login__checkbox2 "
                  />
                </label>
                <span>Check this box if you want to receive promotional emails, book deals, and LinkedIn group updates. You can unsubscribe anytime.</span>
                </div>

                <input type="hidden" name="recaptcha_response" ref={(val) => userInfo.recaptcha_response = val} className="form-control" placeholder="Your password here..." required  defaultValue={value} />

                {load && (
          <ReCAPTCHA
            style={{ display: "inline-block" }}
            theme="dark"
            size="invisible"
            ref={_reCaptchaRef}
            sitekey={TEST_SITE_KEY}
            onChange={handleChange}
            asyncScriptOnLoad={asyncScriptOnLoad}
          />
        )}


                <div className="form-group col-md-12 ">
                {loadinglogin ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
                    <button type="submit" className="thm-btn pricing-one__btn" style={{
                      marginBottom: "37px"}} disabled={!recaptchaLoaded}> Register </button>
                }
                </div>
            </div>
           
        </form>  
        );
    }

export default Registerpage;